import Vue from "vue";
import VueGtag from "vue-gtag";
import router from "./router";

Vue.use(
  VueGtag,
  {
    config: {
      id:
        process.env.NODE_ENV === "production" &&
        !document.location.href.includes("staging")
          ? "G-H461RRVX6K"
          : "UA-166959335-1",
      params: {
        send_page_view: true,
      },
    },
  },
  router,
);
