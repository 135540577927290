import Vue from "vue";
import Vuex from "vuex";
import router from "../plugins/router";
import {
  getProjects,
  getOneProject,
  login,
  logout,
  getAuthUser,
  getAllPerks,
  getInvoices,
  getReferrers,
  getAllComments,
  getDraftProjects,
} from "@/services/api";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    projects: [],
    isProjectsResolved: false,
    oneProject: null,
    isOneProjectResolved: false,
    perks: [],
    isPerksResolved: false,
    invoices: [],
    referrers: [],
    referrerCurrency: null,
    referrersLastUpdate: null,
    referrersActiveDays: [],
    convertToUSD: null,
    converted: null,
    user: null,
    isAuthResolved: false,
    comments: [],
    draftProjects: [],
  },
  actions: {
    async login({ commit }, data) {
      try {
        const { form, query } = data;
        const res = await login(form);
        const user = res.data.data;
        commit("set", { key: "user", value: user });
        commit("set", { key: "isAuthResolved", value: true });
        if (query.path) await router.push(`${query.path}`);
        else await router.push({ name: "user", query: query }).catch(() => {});
        return user;
      } catch (e) {
        return { error: e };
      }
    },
    async logout({ commit }) {
      try {
        await logout();
        commit("set", { key: "user", value: null });
        return true;
      } catch (e) {
        console.log(e);
      }
    },
    async getAuthUser({ commit }) {
      try {
        const res = await getAuthUser();
        const user = res.data.data;
        commit("set", { key: "user", value: user });
        commit("set", { key: "isAuthResolved", value: true });
        return user;
      } catch (e) {
        commit("set", { key: "user", value: null });
        commit("set", { key: "isAuthResolved", value: true });
        console.log(e);
      }
    },
    async getProjects({ commit }) {
      try {
        const res = await getProjects();
        const projects = res.data.data;
        commit("set", { key: "projects", value: projects });
        commit("set", { key: "isProjectsResolved", value: true });
        return projects;
      } catch (e) {
        commit("set", { key: "projects", value: null });
        commit("set", { key: "isProjectsResolved", value: true });
        console.log(e);
      }
    },
    async getOneProject({ commit }, projectTitle) {
      try {
        const res = await getOneProject(projectTitle);
        const oneProject = res.data.data;
        commit("set", { key: "oneProject", value: oneProject });
        commit("set", { key: "isOneProjectResolved", value: true });
        return oneProject;
      } catch (e) {
        commit("set", { key: "oneProject", value: null });
        commit("set", { key: "isOneProjectResolved", value: true });
        console.log(e);
      }
    },
    async getAllPerks({ commit }, limit) {
      try {
        const res = await getAllPerks(limit);
        const perks = res.data.data;
        commit("set", { key: "perks", value: perks });
        commit("set", { key: "isPerksResolved", value: true });
        return perks;
      } catch (e) {
        if (e.response.data.message === "You don't have permission") {
          await router.push({ name: "PageNotFound" });
        } else if (e.response.data.message === "Project does not exist") {
          await router.push({ name: "PageNotFound" });
        }
        console.log(e);
      }
    },
    async getInvoices({ commit }, inv) {
      try {
        const res = await getInvoices(inv);
        const invoices = res.data.data;
        commit("set", { key: "invoices", value: invoices });
        return invoices;
      } catch (e) {
        commit("set", { key: "invoices", value: null });
        console.log(e);
      }
    },
    async getReferrers({ commit }, param) {
      try {
        const res = await getReferrers(param);
        const {
          referrers,
          referrerCurrency,
          lastUpdate,
          activeDays,
          convertToUSD,
          converted,
        } = res.data.data;
        commit("set", { key: "referrers", value: referrers });
        commit("set", { key: "isReferrersResolved", value: true });
        commit("set", { key: "referrerCurrency", value: referrerCurrency });
        commit("set", { key: "referrersLastUpdate", value: lastUpdate });
        commit("set", { key: "referrersActiveDays", value: activeDays });
        commit("set", { key: "convertToUSD", value: convertToUSD });
        commit("set", { key: "converted", value: converted });
        return referrers;
      } catch (e) {
        commit("set", { key: "referrers", value: null });
        commit("set", { key: "isReferrersResolved", value: false });
        commit("set", { key: "referrerCurrency", value: null });
        commit("set", { key: "referrersLastUpdate", value: null });
        commit("set", { key: "referrersActiveDays", value: [] });
        commit("set", { key: "convertToUSD", value: false });
        commit("set", { key: "converted", value: false });

        if (e.response.data.message === "Project does not exist") {
          await router.push({ name: "PageNotFound" });
        }
        console.log(e);
      }
    },
    async getAllComments({ commit }, postId) {
      try {
        const res = await getAllComments(postId);
        const comments = res.data.data;
        commit("set", { key: "comments", value: comments });
        return comments;
      } catch (e) {
        commit("set", { key: "comments", value: null });
        console.log(e);
      }
    },
    async getDraftProjects({ commit }) {
      try {
        const res = await getDraftProjects();
        const draftProjects = res.data.data;
        commit("set", { key: "draftProjects", value: draftProjects });
        return draftProjects;
      } catch (e) {
        commit("set", { key: "draftProjects", value: null });
        console.log(e);
      }
    },
  },
  mutations: {
    set(state, object) {
      return (state[object.key] = object.value);
    },
  },
});
