import axios from "axios";

const login = (form) => axios.post("/api/auth/login", form);

const getAuthUser = () => axios.get("/api/auth/me", { withCredentials: true });

const logout = () => axios.get("/api/auth/logout", { withCredentials: true });

const getAllComments = (postId) =>
  axios.get(`/api/blog/getComments/${postId}`, { withCredentials: true });

const getDraftProjects = () =>
  axios.get("/api/draft/getAll", { withCredentials: true });

const getInvoices = (inv) =>
  axios.get(`/api/invoice/getInvoices/${inv}`, { withCredentials: true });

const getAllPerks = (limit) =>
  axios.get(`/api/perk/getAllPerks/${limit}`, { withCredentials: true });

const getProjects = () =>
  axios.get("/api/project/getProjects", { withCredentials: true });

const getOneProject = (title) => axios.get(`/api/project/getProject/${title}`);

const getReferrers = (param) =>
  axios.get(`/api/referrer/getAllReferrers/${param}`);

export {
  getProjects,
  getOneProject,
  login,
  logout,
  getAuthUser,
  getAllPerks,
  getInvoices,
  getReferrers,
  getAllComments,
  getDraftProjects,
};
