import Vue from "vue";
import VueYandexMetrika from "vue-yandex-metrika";
import router from "./router";

Vue.use(VueYandexMetrika, {
  id:
    process.env.NODE_ENV === "production" &&
    !document.location.href.includes("staging")
      ? "61610227"
      : "62913961",
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});
