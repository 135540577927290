<template>
  <v-app style="overflow: hidden">
    <v-container
      class="ma-0 pa-0"
      style="overflow: hidden; background-color: #f9f9f9; height: 100%"
      fluid
    >
      <router-view></router-view>
    </v-container>
  </v-app>
</template>

<script>
export default {
  mounted() {
    this.$analytics.fbq.init("3955751707847799", {});
  },
};
</script>
