import Vue from "vue";
import VueResource from "vue-resource";
import Vuelidate from "vuelidate";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueScrollTo from "vue-scrollto";
import Toasted from "vue-toasted";
import VueSplide from "@splidejs/vue-splide";
import VWave from "v-wave";
import VueConfetti from "vue-confetti";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import VueMeta from "vue-meta";
import axios from "axios";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import "./gtag";
import "./mixpanel";
import "./yandex_metrika";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "../assets/styles/colors.css";
import "../assets/styles/myStyles.css";
import "../assets/styles/Textings.css";
import "../assets/styles/Headings.css";

window.axios = axios;
Vue.config.productionTip = false;

Vue.use(VueResource);
Vue.use(Vuelidate);
Vue.use(VueSmoothScroll);
Vue.use(VueScrollTo);
Vue.use(Toasted);
Vue.use(VueSplide);
Vue.use(VWave);
Vue.use(VueConfetti);
Vue.use(VueFacebookPixel);
Vue.use(VueMeta);
Vue.use(VueAwesomeSwiper);
