import Vue from "vue";
import VueMixpanel from "vue-mixpanel";

Vue.use(VueMixpanel, {
  token:
    process.env.NODE_ENV === "production" &&
    !document.location.href.includes("staging")
      ? "bd316d0fa944ed530befdb6dd0a25e0e"
      : "ca793fccaced9fe2b10790c4aaf0515f",
});
