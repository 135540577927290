import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Meta from "vue-meta";
Vue.use(Meta);
Vue.use(VueRouter);

function beforeEachRoute(to, from, next) {
  let isAuth = document.cookie.includes("mysession");
  if (to.meta.onlyAuthUser) {
    if (isAuth) {
      store.dispatch("getAuthUser").then(() => {
        if (
          (from.query.topupsummary === "true" || from.query.six_month_promo) &&
          from.query.login === "true"
        ) {
          let oldQuery = from.query;
          delete oldQuery.login;
          next({ name: "user", query: oldQuery });
        } else {
          next();
        }
      });
    } else {
      to.query.activate
        ? next({ name: "home", query: { login: "true" } })
        : Object.keys(to.query).length === 0 && to.query.constructor === Object
        ? next({ name: "home" })
        : next();
    }
  } else if (to.meta.onlyGuestUser) {
    if (isAuth) {
      store.dispatch("getAuthUser").then(() => {
        next({ name: "user" });
      });
    } else {
      next();
    }
  } else {
    if (isAuth) {
      store.dispatch("getAuthUser").then(() => {
        next();
      });
    } else next();
  }
}

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(`../views/HomePage/Index.vue`),
    meta: {
      onlyGuestUser: true,
    },
  },
  {
    path: "/Pricing",
    component: () => import(`@/views/PricingPage/Index`),
    name: "Pricing",
    meta: {
      onlyGuestUser: true,
    },
  },
  {
    path: "/Blog",
    component: () => import(`../views/BlogPage.vue`),
    name: "BlogPage",
    meta: {
      onlyGuestUser: true,
    },
  },
  {
    path: "/Blog/:urlkeywords",
    component: () => import(`../views/BlogPost.vue`),
    name: "BlogPost",
    meta: {
      onlyGuestUser: true,
    },
  },
  {
    path: "/how-it-works",
    component: () => import(`@/views/HowItWorks/Index.vue`),
    name: "HowItWorks",
    meta: {
      onlyGuestUser: true,
    },
  },
  {
    path: "/Terms-and-Conditions",
    component: () => import(`../views/TermsPage.vue`),
    name: "TermsPage",
  },
  {
    path: "/Privacy-Policy",
    component: () => import(`../views/Policy.vue`),
    name: "Policy",
  },
  {
    path: "/user",
    name: "user",
    component: () => import(`../views/Home_User.vue`),
    meta: { onlyAuthUser: true },
  },
  {
    path: "/project/:projectTitle",
    component: () => import(`../views/MyRewards.vue`),
    name: "MyRewards",
    meta: { onlyAuthUser: true },
  },
  {
    path: "/archives",
    component: () => import(`../views/Archives.vue`),
    name: "Archives",
    meta: { onlyAuthUser: true },
  },
  {
    path: "/invoices",
    component: () => import(`../views/InvoicePage.vue`),
    name: "InvoicePage",
    meta: { onlyAuthUser: true },
  },
  {
    path: "/ksdaily/:projectTitle",
    component: () => import(`../views/KSDailyDashboard.vue`),
    name: "KSDaily",
    meta: { onlyAuthUser: true },
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import(`../views/PageNotFound.vue`),
  },
  {
    path: "*",
    redirect: "/404",
  },
];
function scrollBehavior() {
  if (window.location.hash === "#HIWpage") {
    let element = document.querySelector("#HIWpage");
    return { x: 0, y: element.scrollTop };
  }
  return { x: 0, y: 0 };
}
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});
router.beforeEach(beforeEachRoute);
export default router;
